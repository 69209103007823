@import "@fontsource/inter/400.css";
@import "@fontsource/inter/500.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  font-size: 0.8em;
  padding: 2px 0.5em;
  background-color: rgba(0,0,0,0.1);
  border-radius: 3px;
}

body strong {
  font-weight: 500;
}
